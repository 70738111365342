import panzoom from 'panzoom'
import useScroll from '@/composable/useScroll'

import { ref } from 'vue'

const instance = ref({})

const { getRef, resetScroll  } = useScroll()

export default function useZoom() {


    const createInstance = (element) => {
        let inst = panzoom(element, {
            beforeWheel,
            bounds: true,
            boundsPadding: 0.1,
            minZoom: 0.1,
            // maxZoom: 5,
            beforeMouseDown,
            onDoubleClick,
            zoomDoubleClickSpeed: 1,
            // transformOrigin: {x: 0, y: 0}
        })

        instance.value = inst
    }



    const resetZoom = () => {
        if(!instance.value.zoomAbs) return
        instance.value.zoomAbs(0, 0, 1)
        instance.value.moveTo(0, 0)

        resetScroll()
    }

    function beforeWheel(e) {
        // allow wheel-zoom only if altKey is down. Otherwise - ignore
        return !e.altKey;
    }

    function beforeMouseDown(e) {
        // allow wheel-zoom only if altKey is down. Otherwise - ignore
        // const element = document.elementFromPoint(e.clientX, e.clientY);
        // return Boolean(element.closest(".sticky_item"));
        return !e.altKey;
    }

    function onDoubleClick(e) {
        // `e` - is current double click event.
        // tells the library to not preventDefault, and not stop propagation
        return true; 
    }

    return {
        instance,
        resetZoom,
        createInstance,
        getRef
    }
}