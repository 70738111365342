<template>
<div style="outline:none" class="w-full h-full" >
    <div :ref="sceneId">
        <slot></slot>
    </div>
</div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import useZoom from '@/elements/zoom'

// eslint-disable-next-line no-undef
const props = defineProps({
    sceneId: {
        type: String,
        default: 'scene'
    }
})

const scene = ref()

onMounted(() => {
    const { createInstance } = useZoom()
    createInstance(scene.value)
}) 
</script>